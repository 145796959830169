<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(saveUser)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name)) }} <strong>{{ $tc('menu.attributes', 1) }}</strong></span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<InputWithValidation class="mb-3" rules="required|min:2" :label="$tc('fields.name')" v-model="form.name" />

				<div v-if="types.length >= 1" class="mb-3">
					<SelectMultiple :input_label="$tc('menu.types', 2)" v-model="form.types" :options="types" track-by="name" label="name" />
				</div>
				
				<InputWithValidation rules="required" class="mb-3" field="textarea" :label="$t('fields.description')" v-model="form.description" />

				<small class="modal-updated" v-if="form.updated_at">{{ $t('last_change') }} {{ format(form.updated_at) }}</small>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">{{ $t('buttons.close') }}</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">{{ $t('buttons.save') }}</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import SelectMultiple from '@/components/inputs/SelectMultiple'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import '@/mixins/generic'

export default {
	components: {
		InputWithValidation,
		SelectMultiple,
		ValidationObserver
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			visible: false,
			form: {
				name: '',
				description: '',
				types: []
			},
			imageVariations: [], 
			types: [],
			configRequest: {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		}
	},
	methods: {
		async findAllTypes() {
            try {
                const response = await Api.get('types/findAll')
                if (response.status === 200) {
                    this.types = response.data
                }
            } catch (e) {
                console.log(e)
            }
        },
		async update() {
			try {
				this.loading = true

				let formData = new FormData()

				for (var i = 0; i < this.form.types.length; i++) {
					formData.append('types[]', this.form.types[i].id)
				}

				formData.append('_method', 'put')
				formData.append('name', this.form.name)
				formData.append('description', this.form.description || '')

				const response = await Api.post(`attributes/update/${this.id}`, formData, this.configRequest)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/attributes')
					successToast(this.$t('alerts.update.success_f', [this.$tc('fields.variation')]))
					eventHub.$emit('reload-attributes')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast(this.$t('alerts.update.error_f', [this.$tc('fields.variation')]))
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				this.variations = []
				this.imageVariations = []
				try {
					const response = await Api.get(`attributes/findById/${this.id}`)
					if (response.status === 200) {
						const { data } = response
						this.form.name = data.name
						this.form.description = data.description
						this.form.types = data.types

						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async store() {
			try {
				this.loading = true

				let formData = new FormData()

				for (var i = 0; i < this.form.types.length; i++) {
					formData.append('types[]', this.form.types[i].id)
				}

				formData.append('name', this.form.name)
				formData.append('description', this.form.description || '')

				const response = await Api.post('attributes/store', formData, this.configRequest)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/attributes')
					successToast(this.$t('alerts.create.success_f', [this.$tc('fields.variation')]))
					eventHub.$emit('reload-attributes')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(this.$t('alerts.create.error_f', [this.$tc('fields.variation')]) + '<small>' + message + '</small>')
				}
			} finally {
				this.loading = false
			}
		},
		async saveUser() {
			this.name === 'New' ? await this.store() : await this.update()
		}
	},
	mounted() {
		this.findAllTypes()
		this.findById()
	}
}
</script>
